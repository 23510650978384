import { yupResolver } from "@hookform/resolvers/yup";
import { useCountries, useShoppingCart, useStores } from "@sushicorp/contexts";
import { useShippingAddress } from "@sushicorp/contexts";
import { PostShippingAddressPayload } from "@sushicorp/services";
import { PutShippingAddressPayload } from "@sushicorp/services";
import { useFetchShippingAddresses } from "@sushicorp/services";
import { usePostShippingAddress } from "@sushicorp/services";
import { usePutShippingAddress } from "@sushicorp/services";
import { defaultFunction } from "@sushicorp/utils";
import { trimFields } from "@sushicorp/utils";
import { events } from "artisn/analytics";
import { CommentBox, TextInput } from "artisn-ui-react";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import { getCommentHelper } from "./AddressForm.helpers";
import { getSelectedPostPayload } from "./AddressForm.helpers";
import { AddressFormSchema, getDefaultNickname } from "./AddressForm.helpers";
import { getSelectedPutPayload } from "./AddressForm.helpers";
import Styles from "./AddressForm.styles";
import { AddressFormValues } from "./AddressForm.types";
import { AddressFormProps as Props } from "./AddressForm.types";
import Button from "components/global/Button/Button";
import Checkbox from "components/global/Checkbox/Checkbox";
import Divider from "components/global/Divider/Divider";
import { InfoShoppingCartModal } from "components/global/InfoShoppingCartModal/InfoShoppingCartModal";
import useInfoShoppingCartModal from "components/global/InfoShoppingCartModal/InfoShoppingCartModal.hooks";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import useBenefit from "hooks/useBenefit/useBenefit";
import { useDeleteShoppingCart } from "hooks/useDeleteShoppingCart";
import useI18n from "hooks/useI18n";
import useValidateStoreGeo from "hooks/useValidateStoreGeo";
import { notify } from "utils/common.utils";
import { createErrorNotification } from "utils/notifications.utils";

import HeartLineSVG from "../../../../public/assets/images/heart-line.svg";
import HomeSVG from "../../../../public/assets/images/home.svg";
import WorkSVG from "../../../../public/assets/images/work.svg";

const { logAddShippingAddress, logUpdateShippingAddress } = events.shipping;

const AddressForm: React.FC<Props> = props => {
  const t = useI18n();
  const { className, initialValues, method, mapAddress } = props;
  const { onPressButton = defaultFunction, disabled, editAddress } = props;
  const { geometry } = mapAddress ?? {};
  const { location: coordinates } = geometry ?? {};
  const auth = useAuth();
  const [errorMessage, setErrorMessage] = useState("");
  const [nicknameErrorMessage, setNicknameErrorMessage] = useState("");
  const { setSelectedStore } = useStores();
  const { validateStoreGeo } = useValidateStoreGeo();
  const { data: addressList } = useFetchShippingAddresses(auth, notify);
  const { selectedCountry } = useCountries();
  const { isAnonymous } = useAuth();
  const { setSelectedShippingAddress } = useShippingAddress();
  const { isEmptyShoppingCart, emptyCartHandler } = useDeleteShoppingCart();
  const form = useForm<AddressFormValues>({
    mode: "onChange",
    resolver: yupResolver(AddressFormSchema),
    defaultValues: {
      ...initialValues,
      numberContactAddress: "",
      addressNumber: "",
      nickname: getDefaultNickname(initialValues?.nickname),
      otherNickname: initialValues?.nickname,
      default: !addressList?.length ? true : initialValues?.default,
      comment: getCommentHelper(initialValues)
    }
  });
  const { handleSubmit, register, formState, watch } = form;
  const watchNickname = watch("nickname");
  const watchOtherNickname = watch("otherNickname");
  const selectedNickname = watchNickname === "Otro";
  const showInput = selectedNickname
    ? "AddressForm__nickname-input--show"
    : null;
  const postShippingAddress = usePostShippingAddress(auth);
  const { mutate: postMutate, reset: postReset } = postShippingAddress;
  const { isLoading: isLoadingPost } = postShippingAddress;
  const putShippingAddress = usePutShippingAddress(auth);
  const { mutate: putMutate, reset: putReset } = putShippingAddress;
  const { isLoading: isLoadingPut } = putShippingAddress;
  const { reference } = formState.errors ?? {};
  const { comment, mainStreet, secondaryStreet } = formState.errors ?? {};
  const { shoppingCart } = useShoppingCart();
  const { keepCartHandler } = useInfoShoppingCartModal();
  const { reApplyBenefit } = useBenefit();
  const isGeoChanged = useMemo(() => {
    if (!mapAddress) return false;
    const isLatChanged = coordinates.lat !== editAddress?.lat;
    const isLngChanged = coordinates.lng !== editAddress?.lng;
    return isLatChanged || isLngChanged;
  }, [coordinates, editAddress, mapAddress]);

  /**
   * Create new address.
   *
   * @param {PostShippingAddressPayload} postAddress Address data to create.
   */
  const createAddress = (postAddress: PostShippingAddressPayload) => {
    const { mainStreet, secondaryStreet, number, nickname } = postAddress;

    postMutate(postAddress, {
      onError: () => {
        setErrorMessage("Ocurrió un problema al guardar la dirección de envío");
        console.error("An error occurred in the POST request");
      },
      onSuccess: () => {
        logAddShippingAddress({
          address: `${mainStreet} ${secondaryStreet} ${number}`,
          alias: nickname
        });
        onPressButton();
        postReset();
      }
    });
  };

  /**
   * Update address data.
   *
   * @param {PutShippingAddressPayload} putAddress Address data to update
   */
  const updateAddress = (putAddress: PutShippingAddressPayload) => {
    const { mainStreet, secondaryStreet, number, id } = putAddress;

    putMutate(putAddress, {
      onError: () => {
        setErrorMessage(
          "Ocurrió un problema al actualizar la dirección de envío"
        );
        console.error("An error occurred in the POST request");
      },
      onSuccess: () => {
        logUpdateShippingAddress({
          address: `${mainStreet} ${secondaryStreet} ${number}`,
          addressId: id,
          fields: Object.keys(formState.touchedFields)
        });
        onPressButton();
        putReset();
      }
    });
  };

  /**
   * Execute in form submit event.
   *
   * @param {AddressFormValues} form Address form data.
   */
  const onSubmitHandler = (form: AddressFormValues) => {
    const newForm = trimFields(form);

    setErrorMessage("");
    if (selectedNickname && !watchOtherNickname) {
      setNicknameErrorMessage("Campo requerido");
      return;
    }
    if (isAnonymous) {
      setSelectedShippingAddress({
        ...getSelectedPostPayload(newForm, mapAddress, selectedCountry),
        id: 1,
        createdAt: "",
        country: {
          id: CONSTANTS.ARTISN.DEFAULT_COUNTRY.id,
          name: CONSTANTS.ARTISN.DEFAULT_COUNTRY.name
        }
      });
      onPressButton();
      return;
    }
    if (method === "POST") {
      createAddress(
        getSelectedPostPayload(newForm, mapAddress, selectedCountry)
      );
    }
    if (method === "PUT") {
      updateAddress(
        getSelectedPutPayload(
          newForm,
          mapAddress,
          selectedCountry,
          initialValues!,
          editAddress!
        )
      );
    }
  };

  const submitHandler = async (form: AddressFormValues) => {
    const { default: willBeDefault } = form;
    if (
      (willBeDefault && method === "POST") ||
      (willBeDefault &&
        method === "PUT" &&
        isGeoChanged &&
        ((!isEmptyShoppingCart && !isAnonymous) || !isEmptyShoppingCart))
    ) {
      try {
        const isConfirmed = await InfoShoppingCartModal({});
        if (typeof isConfirmed === "undefined") return;
        if (!isConfirmed) {
          const newStore = await validateStoreGeo(coordinates);
          const [benefit] = shoppingCart?.benefits ?? [];
          await keepCartHandler(coordinates);
          onSubmitHandler(form);
          setSelectedStore(newStore);
          await reApplyBenefit(benefit);
          return;
        }
        emptyCartHandler();
        onSubmitHandler(form);
      } catch (e) {
        console.error(e);
        createErrorNotification(e.message);
      }
      return;
    }
    onSubmitHandler(form);
  };

  return (
    <Styles className={`AddressForm ${className}`}>
      <Divider className="AddressForm__divider" />
      <form onSubmit={handleSubmit(submitHandler)} autoComplete="off">
        <TextInput
          {...register("mainStreet")}
          className="AddressForm__text-input"
          label={t.profile.address.form.label.mainStreet}
          placeholder={t.profile.address.form.placeholder.mainStreet}
          errorMessage={mainStreet?.message}
          maxLength={50}
        />
        <TextInput
          {...register("secondaryStreet")}
          className="AddressForm__text-input"
          label={t.profile.address.form.label.secondaryStreet}
          placeholder={t.profile.address.form.placeholder.secondaryStreet}
          errorMessage={secondaryStreet?.message}
          maxLength={50}
        />
        <TextInput
          {...register("reference")}
          className="AddressForm__text-input"
          label={t.profile.address.form.label.reference}
          placeholder={t.profile.address.form.placeholder.reference}
          errorMessage={reference?.message}
          maxLength={50}
        />
        <div className="AddressForm__buttonGroup">
          <Divider className="AddressForm__divider" />
          <div className="AddressForm__buttonGroup__title">
            {t.profile.address.form.labelName}
          </div>
          <div className="AddressForm__buttonGroup__options">
            <label className="AddressForm__option">
              <input
                {...register("nickname")}
                type="radio"
                value="Casa"
                className="AddressForm__option__radio"
              />
              <span
                className={`AddressForm__option__button ${
                  watchNickname === "Casa"
                    ? "AddressForm__option__button--selected"
                    : ""
                }`}
              >
                <HomeSVG />
                {t.profile.address.form.house}
              </span>
            </label>
            <label className="AddressForm__option">
              <input
                {...register("nickname")}
                type="radio"
                value="Trabajo"
                className="AddressForm__option__radio"
              />
              <span
                className={`AddressForm__option__button ${
                  watchNickname === "Trabajo"
                    ? "AddressForm__option__button--selected"
                    : ""
                }`}
              >
                <WorkSVG />
                {t.profile.address.form.work}
              </span>
            </label>
            <label className="AddressForm__option">
              <input
                {...register("nickname")}
                type="radio"
                value="Otro"
                className="AddressForm__option__radio"
              />
              <span
                className={`AddressForm__option__button ${
                  watchNickname === "Otro"
                    ? "AddressForm__option__button--selected"
                    : ""
                }`}
              >
                <HeartLineSVG />
                {t.profile.address.form.others}
              </span>
            </label>
          </div>
          <Divider className="AddressForm__divider" />
          <TextInput
            {...register("otherNickname")}
            className={`AddressForm__nickname-input ${showInput} field `}
            label="Otros"
            placeholder={t.profile.address.form.placeholder.others}
            errorMessage={nicknameErrorMessage}
            maxLength={50}
          />
          {method === "POST" && addressList?.length !== 0 && !isAnonymous ? (
            <Checkbox
              {...register("default")}
              label={t.profile.address.form.usePredetermined}
              name="default"
              className="AddressForm__checkbox field"
            />
          ) : null}
        </div>

        <CommentBox
          {...register("comment")}
          className="AddressForm__text-input AddressForm__comment"
          label="Agrega una nota de entrega para esta dirección (opcional)"
          placeholder={t.common.forms.placeholder.comment}
          errorMessage={comment?.message}
          maxLength={50}
          rows={3}
        />

        {errorMessage ? (
          <p className="AddressForm__error-message">{errorMessage}</p>
        ) : null}
        <Button
          className="AddressForm__button"
          isLoading={isLoadingPost || isLoadingPut}
          htmlType="submit"
          disabled={disabled}
        >
          {t.profile.address.form.saveAddress}
        </Button>
      </form>
    </Styles>
  );
};

AddressForm.defaultProps = {
  className: ""
};

export default AddressForm;
