import styled from "styled-components";

import { AddressListStyledProps as Props } from "./AddressList.types";
import CONSTANTS from "config/constants";

const { desktop } = CONSTANTS.BREAKPOINTS;

const AddressListStyled = styled.div<Props>`
  flex: 1;

  .AddressList {
    &__item {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 3.2rem;
      }

      .ListItem {
        padding-left: 1.2rem;
        border-left: 0.1rem solid transparent;

        @media (min-width: ${desktop}px) {
          padding-left: 2.4rem;
        }
      }

      &__radio {
        width: 2rem;
        height: 2rem;
        border-radius: 2rem;
        border: 0.1rem solid var(--palette-gray-s0-l80);
        background-color: var(--palette-white);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &--active {
          background-color: var(--palette-white);
          border: 0.56rem solid var(--palette-primary);
        }
      }
    }

    &__student-icon {
      margin-right: 2rem;

      path {
        fill: var(--palette-gray-s0-l80);
      }
    }

    &__empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      text-align: center;
      padding: 6.4rem 0;

      &__title {
        color: var(--palette-black-s0-l10);
        font-size: 2.4rem;
        padding-bottom: 1.2rem;
        padding-top: 1.2rem;
        font-weight: 700;
      }

      &__description {
        color: var(--palette-gray-s0-l40);
        line-height: 2rem;

        @media (min-width: ${desktop}px) {
          max-width: 26.4rem;
        }
      }

      &__icon {
        width: 8.8rem;
        height: 8.8rem;
        background-color: var(--palette-red-h360-s73-l54-a03);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          margin: 0;

          path {
            fill: var(--palette-primary);
          }
        }
      }
    }
  }
`;

export default AddressListStyled;
