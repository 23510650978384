import { getMaxHeight } from "@sushicorp/utils";
import { opacify } from "polished";
import { css } from "styled-components";
import "react-multi-carousel/lib/styles.css";

import CONSTANTS from "config/constants";
import variables from "styles/util/variables";

const primary = variables.palette["primary"];

const { mobile, tablet } = CONSTANTS.BREAKPOINTS;

const base = css`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  html {
    font-size: 62.5%; //16px default to 10px
  }

  body {
    box-sizing: border-box;
    background-color: var(--palette-gray-s0-l98);
    max-width: 100%;
    margin-top: env(safe-area-inset-top);
    margin-bottom: env(safe-area-inset-bottom);
    margin-left: env(safe-area-inset-left);
    margin-right: env(safe-area-inset-right);
  }

  :is(.TabsMenu__page, .Layout) > * {
    grid-template-rows: max-content 1fr max-content;
  }

  .SettingsDrawer {
    &__separator {
      height: 0.8rem;
      background-color: var(--palette-gray-s0-l98);
      margin-left: -2.4rem;
      margin-right: -2.4rem;
    }

    &__info-card {
      padding: 1.6rem 0;
    }

    &__drawer {
      &__header {
        position: relative;
        padding-bottom: 4rem;

        &__title {
          text-align: center;
          font-family: Inter;
          font-size: 1.6rem;
          line-height: 2.4rem;
        }

        &__cross {
          position: absolute;
          top: 0;
          right: 0;
          cursor: pointer;
        }
      }

      &__buttonGroupText {
        font-family: Inter;
        font-size: 1.2rem;
        line-height: 2rem;
        color: var(--palette-gray-s0-l40);
      }

      &__button {
        margin-top: auto;
        display: flex;
        justify-content: center;
        padding: 1rem 1.6rem;
        background: var(--palette-black-s0-l10);
        border-radius: 0.4rem;
        border: none;
        cursor: pointer;

        &__text {
          font-family: Inter;
          font-size: 1.6rem;
          line-height: 2.4rem;
          color: var(--palette-white);
        }
      }
    }

    &__buttonGroup {
      padding-top: 0.8rem;
      padding-bottom: 2.4rem;

      .Button--active {
        border: 0.1rem solid var(--palette-primary) !important;
        color: var(--palette-primary);
      }

      &__button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1.6rem 2.4rem;
        border: 0.1rem solid var(--palette-gray-s0-l80);
        width: 11.2rem;
        height: 4.8rem;
        font-size: 1.6rem;

        &:first-child {
          border-top-left-radius: 0.4rem;
          border-bottom-left-radius: 0.4rem;
        }

        &:last-child {
          border-top-right-radius: 0.4rem;
          border-bottom-right-radius: 0.4rem;
        }
      }
    }
  }

  .SearchBar__item--active {
    background-color: var(--palette-gray-s0-l98);
  }

  .CheckoutOrderStatus {
    &__modal {
      width: 100%;
      height: ${getMaxHeight};
    }
  }

  .Modal {
    border-radius: 1.2rem !important;
    overflow: hidden;

    @media (max-width: ${mobile}px) {
      height: ${getMaxHeight};
    }

    &--rounded {
      border-radius: 2rem !important;

      @media (max-width: ${mobile}px) {
        width: 100% !important;
        height: 100% !important;
      }

      & .Modal {
        &__body {
          display: flex;
          align-items: center;
        }

        &__close-icon {
          top: 3.2rem;
          right: 2.4rem;
        }
      }
    }
  }

  .SearchBar__wrapper,
  .SearchBar__btn {
    border-radius: 0.8rem;
  }

  .SearchBar__wrapper > .SearchBar__btn.SearchBar__btn-search {
    padding-left: 1.2rem;
    width: 3.6rem;
  }

  .Button {
    &--no-hover {
      && .Button {
        &:hover {
          box-shadow: none;
          border: none;
        }
      }
    }
  }

  .Category,
  .Products {
    &__drawer {
      width: 100%;
      height: 100%;
      padding-top: 1.6rem;

      &__close {
        position: absolute;
        top: 2.4rem;
        right: 2rem;
        z-index: 2;
        cursor: pointer;
      }
    }

    &__results {
      position: absolute;
      width: 100%;
      bottom: 0;
      padding: 1.6rem 2.4rem;
      background-color: var(--palette-white);
      z-index: 2;

      &&& &__button {
        font-family: inherit;
        width: 100%;
        justify-content: center;
        padding: 1.8rem 2.4rem;
      }
    }
  }

  .field {
    &&& label {
      color: var(--palette-black-s0-l10);
      padding-bottom: 0.4rem;
      font-size: 1.4rem;
      position: absolute;
      padding-top: 1.2rem;
      padding-left: 1.2rem;
      z-index: 1;
      font-weight: 700;

      @media (max-width: ${mobile}px) {
        font-size: 1.2rem;
      }

      &.Checkbox__label {
        padding-bottom: 0;
      }

      &:disabled {
        opacity: 0.5;
      }
    }

    &&& input {
      padding: 0.4rem 1.2rem;
      padding-top: 3.2rem;
      font-size: 1.6rem;
      color: var(--palette-black-s0-l10);
      background-color: var(--palette-gray-s0-l98);
      transition: border 0.2s ease-in-out;
      border: 0.1rem solid var(--palette-gray-s0-l95);
      font-weight: 400;
      border-radius: 0.4rem;
      outline: none;
      line-height: 3rem;

      &:disabled {
        opacity: 0.5;
      }

      &:hover:not([disabled]) {
        border: 0.1rem solid var(--palette-primary);
      }

      &::placeholder {
        color: var(--palette-gray-s0-l25);
        opacity: 70%;
        font-weight: 400;
      }

      &:focus {
        border-color: var(--palette-primary);
        box-shadow: 0px 0px 0px 0.1rem ${opacify(-0.8, primary)};
      }

      &.PhoneNumberInput__input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border: 0.1rem solid var(--palette-gray-s0-l95);

        &:hover {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          z-index: 0;
        }
      }
    }

    &&& figure {
      z-index: 1;
    }

    &&& .SelectCountry {
      background-color: var(--palette-gray-s0-l98);
      border: 0.1rem solid var(--palette-gray-s0-l95);
      transition: border 0.2s ease-in-out;

      &:hover {
        border: 0.1rem solid var(--palette-primary);

        & > * {
          border-color: var(--palette-primary);
        }
      }

      &__flag {
        border-color: var(--palette-gray-s0-l95);
      }

      &__select {
        border-color: var(--palette-gray-s0-l95);
      }
    }

    &&& .DocumentTypeSelect__selectContainer {
      background-color: var(--palette-gray-s0-l98);
      border-color: var(--palette-gray-s0-l95);
      padding-left: 0.8rem;
      padding-right: 0.8rem;
      padding-top: 2.8rem;
      transition: border 0.2s ease-in-out;

      &:hover {
        border: 0.1rem solid var(--palette-primary);
      }
    }
  }

  input.TextInput__input {
    &::placeholder {
      color: var(--palette-gray-s0-l90);
    }
  }

  .border__item {
    display: grid !important;
    grid-template-columns: max-content 1fr max-content;
    align-items: center !important;
    padding: 1.2rem 1.6rem !important;
    min-height: 6.4rem !important;
    border-radius: 0.4rem !important;

    &--inactive {
      border: 0.1rem solid var(--palette-gray-s0-l95) !important;
    }
  }

  .loading-shine {
    background-color: #f0f0f0;
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      display: block;
      background: linear-gradient(
        to right,
        transparent,
        rgba(180, 180, 180, 0.3),
        transparent
      );
      animation: load 1.5s cubic-bezier(0.4, 0, 0.3, 1) infinite;
      position: absolute;
      left: -100%;
      top: 0;
      height: 100%;
      width: 100%;
    }
  }

  div.SettingsDrawer__drawer {
    padding: 1.6rem 2.4rem;
    padding-top: calc(1.6rem + env(safe-area-inset-top));
    min-width: 40rem;
    min-height: var(--sizes-page-maxHeight);
    max-height: calc(var(--sizes-page-maxHeight) - 5.7rem);

    @media (hover: none) {
      width: var(--sizes-page-maxWidth);
      min-height: calc(var(--sizes-page-maxHeight) - 5.7rem);
    }
  }

  .SettingsDrawer {
    &__buttonGroup {
      &__button {
        flex: 1;
      }
    }
  }

  .NotificationsContainer {
    & &__container {
      @media (max-width: ${tablet}px) {
        bottom: 7.2rem;
      }

      @media (max-width: ${mobile}px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        right: 0.6rem;
        left: 0.6rem;
      }
    }
  }

  .UpdateAppModal,
  .OutOfServiceModal {
    &__backdrop {
      height: 100vh !important;
    }
  }

  .SelectAddressDropdown__backdrop {
    content-visibility: unset !important;
  }
`;

export default base;
