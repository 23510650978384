import styled from "styled-components";

import { ProductCardStyledProps as Props } from "./ProductCard.types";
import { ProductCardPlaceholderStyledProps as PlaceholderProps } from "./ProductCard.types";

const ProductCardStyled = styled.div<Props>`
  & > * {
    opacity: ${props => (props.available ? 1 : 0.6)};
    pointer-events: ${props => (props.available ? "all" : "none")};
  }

  & * {
    transition: all 0.5s ease;
  }

  &:hover {
    border-radius: 0.8rem;

    .ProductCard {
      &__button {
        background-color: var(--palette-primary);
        color: var(--palette-white);

        &:hover:not([disabled]) {
          cursor: pointer !important;
        }
      }

      &__image {
        & img {
          scale: 1.5;
        }
      }
    }
  }

  .ProductCard {
    &__card {
      padding: 0.8rem;
    }

    &__button {
      border: 2px solid var(--palette-primary);
      background-color: transparent;
      border-radius: 7.6rem;
      width: 100%;
      padding-block: 1.2rem;
      color: var(--palette-primary);
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 2rem;
      margin-top: 1.6rem;
    }

    &__image {
      .Image__img {
        width: 100%;
        border-radius: 0.8rem;
      }
    }

    &__name {
      font-size: 1.6rem;
      color: var(--palette-black-s0-l10);
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      width: 100%;
      line-height: 1.8rem;
      font-weight: 400;
      font-family: inherit;
      margin-top: 0.4rem;
    }

    &__description {
      font-family: inherit;
      font-weight: 400;
      font-size: 1.4rem;
      color: var(--palette-black-s0-l10);
      line-height: 2rem;
      margin-top: 0.4rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      width: 100%;
    }

    &__price {
      line-height: 1.2rem;
      margin-top: 0.8rem;
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      font-weight: 600;
    }
  }
`;

export const ProductCardPlaceholderStyled = styled.div<PlaceholderProps>`
  width: 100%;
  padding: 0 0.8rem;

  .ProductCardPlaceholder {
    &__image {
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
      height: 20rem;
      overflow: hidden;
      position: relative;
      width: 100%;

      &__shine {
        animation: shine 2.5s ease-in-out infinite;
        background-color: var(--palette-gray-s0-l98);
        height: 350%;
        position: absolute;
        width: 25%;
        opacity: 0.4;
      }
    }

    &__price {
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
      height: 1.6rem;
      margin: 1.2rem 0 0.8rem;
      overflow: hidden;
      position: relative;
      width: 4.5rem;

      &__shine {
        animation: shine 2.5s ease-in-out infinite;
        background-color: var(--palette-gray-s0-l98);
        height: 300%;
        position: absolute;
        width: 25%;
        opacity: 0.4;
      }
    }

    &__name {
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
      height: 1.6rem;
      overflow: hidden;
      position: relative;
      width: 8.4rem;

      &__shine {
        animation: shine 2.5s ease-in-out infinite;
        background-color: var(--palette-gray-s0-l98);
        height: 300%;
        position: absolute;
        width: 25%;
        opacity: 0.4;
      }
    }

    &__add {
      background-color: var(--palette-gray-s0-l95);
      border-radius: 10rem;
      height: 4.8rem;
      overflow: hidden;
      position: relative;
      width: 100%;
      margin-top: 1.2rem;

      &__shine {
        animation: shine 2.5s ease-in-out infinite;
        background-color: var(--palette-gray-s0-l98);
        height: 300%;
        position: absolute;
        width: 25%;
        opacity: 0.4;
      }
    }
  }
`;

export default ProductCardStyled;
