import styled from "styled-components";

import { AddToCartNotificationStyledProps as Props } from "./AddToCartNotification.types";
import Notification from "components/global/notifications/Notification/Notification";
import CONSTANTS from "config/constants";

const { mobile } = CONSTANTS.BREAKPOINTS;

export const AddToCartNotificationStyled = styled(Notification)<Props>`
  background-color: var(--palette-gray-s0-l97);
  border-radius: 1.2rem;
  box-shadow: none;

  @media (max-width: ${mobile}px) {
    padding: 1.2rem;
    width: 100%;
    margin-inline: auto;

    span {
      font-size: 1.2rem;
    }
  }

  svg.Notification__close {
    @media (max-width: ${mobile}px) {
      top: 2.4rem;
    }
  }

  .AddToCartNotification {
    &__head {
      font-family: inherit;
      font-size: 2rem;
      color: var(--palette-black-s0-s10);
      font-weight: 600;
      width: 100%;
      height: 2.8rem;
      display: flex;
      align-items: center;
      margin-bottom: 1.2rem;
    }

    &__content {
      display: flex;
      background-color: var(--palette-white);
      border-radius: 1.2rem;
      padding: 0.4rem;
    }

    &__image {
      width: 100%;
      height: auto;
      max-width: 10.4rem;
      max-height: 12rem;
      min-height: 10.4rem;

      .Image__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        text-align: center;
        border-radius: 1.2rem;
      }
    }

    &__summary {
      padding: 1.6rem;
    }

    &__name {
      font-size: 1.6rem;
      color: var(--palette-black-s0-s10);
      font-weight: 400;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      width: 100%;
    }

    &__description {
      font-family: inherit;
      font-size: 1.4rem;
      font-weight: 400;
      color: var(--palette-gray-s0-l35);
      margin-top: 0.4rem;
    }

    &__product-amount {
      margin-top: 0.4rem;
      font-size: 1.4rem;
      color: var(--palette-white);
      font-weight: 700;
    }

    &__actions {
      margin-top: 1.6rem;
      display: flex;
      justify-content: space-between;
    }

    &__button {
      font-weight: 700;
      font-size: 1.4rem;

      &--cart,
      &--cart:hover {
        padding-inline: 2rem;
        padding-block: 1.2rem;
        color: var(--palette-primary);
        background-color: var(--palette-white);
        border: 0.2rem solid var(--palette-primary);
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 2rem;

        &:hover {
          background-color: var(--palette-white) !important;
          color: var(--palette-primary) !important;
          border: 0.2rem solid var(--palette-primary) !important;
        }
      }

      &--checkout.CartPayButton {
        flex: 1;
        margin-left: 1.2rem;

        .Button.Cart__summary__button {
          justify-content: center;
          padding-top: 1.2rem;
          padding-bottom: 1.2rem;
          font-size: 1.4rem;
        }
      }
    }
  }
`;

export const AddToCartNotificationModifiable = styled(Notification)<Props>`
  display: grid;
  align-items: center;
  padding: 2rem 1.4rem;
  border-radius: 1rem;
  background-color: var(--palette-black-s2-l17);
  box-shadow: 0 0.4rem 1.6rem var(--palette-gray-s0-l20-a08),
    0 0.4rem 0.4rem var(--palette-gray-s0-l20-a04),
    inset 0 0 0 0.2rem var(--palette-black-s2-l17);

  @media (max-width: ${mobile}px) {
    padding: 1.2rem;
  }

  .ShoppingCartProduct {
    margin-top: 2.4rem;
    background-color: var(--palette-black-s3-l11);
  }

  .AddToCartNotification {
    &__name {
      font-size: 1.6rem;
      color: var(--palette-white);
      font-weight: 700;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      width: 100%;
    }

    &__buttons {
      display: flex;
    }

    &__button {
      padding: 0;
      font-weight: 600;
      font-size: 1.6rem;
      border-radius: 0.8rem;
      width: 14.4rem;

      &.Button {
        margin-right: 1.2rem;
        padding: 0 1.2rem;
        font-size: 1.4rem;
      }
    }
  }

  .CartPayButton {
    display: flex;
  }

  .CartPayButton .Cart__summary__button.Button {
    height: 5.6rem;
    padding: 0;
    font-weight: 700;
    font-size: 1.4rem;
    border-radius: 0.8rem;
    justify-content: center;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    height: 4.4rem;
  }

  .Notification__close {
    @media (max-width: ${mobile}px) {
      top: 1.2rem;
      transform: initial;
    }
  }
`;
