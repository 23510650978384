import { getShoppingCartTotal } from "artisn/shopping-cart";
import { Benefit, ShoppingCart } from "artisn/types";

export const getApplyBenefitError = (error: string, t: any) => {
  switch (error) {
    case "Cannot apply DISCOUNT_FIXED benefit because discount is greater than cart subtotal.":
      return t.errors.coupons.exceedsAmount;
  }
};

// Needed because artisn sdk (apply benefit) doesn't raise exceptions in "production", just logs them to console.error.
export const preCheckBenefit = (
  benefit: Benefit,
  shoppingCart: ShoppingCart | undefined
) => {
  if (!shoppingCart) throw new Error("Need a shopping cart to apply benefit");
  const { subtotal } = getShoppingCartTotal(shoppingCart) ?? {};
  if (
    benefit.type === "DISCOUNT_FIXED" &&
    subtotal < +(benefit.discountFixed ?? 0)
  ) {
    throw new Error(
      "Cannot apply DISCOUNT_FIXED benefit because discount is greater than cart subtotal."
    );
  }
};
