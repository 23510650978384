import { useCatalogues, useCountries } from "@sushicorp/contexts";
import { useShoppingCart, useStores, useVendors } from "@sushicorp/contexts";
import { usePackageCost } from "@sushicorp/contexts";
import { useFetchPackageCost } from "@sushicorp/services";
import { formatByCurrency } from "@sushicorp/utils";
import { getShoppingCartTotal } from "artisn/shopping-cart";
import { ShoppingCartSummary as ShoppingCartSummaryUI } from "artisn-ui-react";
import React, { useMemo } from "react";

import Styles from "./ShoppingCartSummary.styles";
import { ShoppingCartSummaryProps as Props } from "./ShoppingCartSummary.types";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import useDefaultCurrencyFormatter from "hooks/useDefaultCurrencyFormatter";
import useI18n from "hooks/useI18n";
import { getDiscountTotalUtility } from "utils/shoppingCart.utils";
import { getCartShippingCost } from "utils/shoppingCart.utils";
import { getProductsSubtotalUtility } from "utils/shoppingCart.utils";

const { ShippingCost, Total } = ShoppingCartSummaryUI;
const { Subtotal, Discount, Points } = ShoppingCartSummaryUI;
const { WITH_LOYALTY, WITH_PACKAGE_COST } = CONSTANTS.FEATURE_FLAGS;

const ShoppingCartSummary: React.FC<Props> = props => {
  const { className, showTotal = true, inCart = false } = props;
  const t = useI18n();
  const auth = useAuth();
  const { selectedVendorId } = useVendors();
  const { selectedStore } = useStores();
  const { selectedCatalogueId } = useCatalogues();
  const { shoppingCart } = useShoppingCart();
  const { selectedCountry } = useCountries();
  const { shippingCost } = shoppingCart ?? {};
  const { total: shippingTotal } = shippingCost ?? {};
  const shoppingCartTotal = useMemo(() => {
    if (!shoppingCart) return;
    return getShoppingCartTotal(shoppingCart);
  }, [shoppingCart]);
  const { total = 0 } = shoppingCartTotal ?? {};
  const defaultFormatter = useDefaultCurrencyFormatter();
  const subTotal = getProductsSubtotalUtility(shoppingCart);

  const discountToShow = getDiscountTotalUtility(shoppingCart);
  const totalToPay = subTotal
    ? subTotal + (shippingTotal ?? 0) - (discountToShow ?? 0)
    : total ?? 0;

  const shippingCostTotal = getCartShippingCost(shoppingCart) ?? 0;
  const { storeId } = selectedStore ?? {};
  const packageCostValue = useFetchPackageCost(auth, {
    extraChargeId: 3,
    storeId: storeId,
    vendorId: +selectedVendorId,
    catalogueId: +selectedCatalogueId
  });
  const { setSelectedPackageCost } = usePackageCost();
  const { data: packageCosts, isFetching } = packageCostValue;

  const packageCost = useMemo(() => {
    if (!packageCosts) return;

    const [packageCost] = packageCosts;
    setSelectedPackageCost(packageCost);

    return packageCost;
  }, [packageCosts, setSelectedPackageCost]);

  const { price: packageCostPrice = 0 } = packageCost ?? {};
  const showInfo =
    WITH_PACKAGE_COST && !isFetching && packageCosts?.length && inCart;
  const enhancedShoppingCartTotal = useMemo(() => {
    if (!shoppingCart) return;
    const { price } = packageCost ?? {};

    const packageCostTotal =
      WITH_PACKAGE_COST && Boolean(packageCost) ? +(price ?? 0) : 0;

    return {
      totalDiscounts: discountToShow,
      shippingCost: shippingCostTotal,
      total: totalToPay + packageCostTotal,
      subtotal: subTotal ?? (total ?? 0) - (shippingCost?.total ?? 0),
      totalTaxes: 0,
      totalPoints: 0
    };
  }, [
    shoppingCart,
    packageCost,
    discountToShow,
    shippingCostTotal,
    total,
    shippingCost?.total,
    subTotal,
    totalToPay
  ]);

  const { currency, currencySymbol } = selectedCountry;

  const renderPackageCost = () => (
    <div className="ShoppingCartSummary__package-cost__content">
      <p className="ShoppingCartSummary__package-cost__title">
        {t.cart.packagingCosts}:
      </p>
      <div className="ShoppingCartSummary__package-cost__value">
        {formatByCurrency(+packageCostPrice, defaultFormatter)}
      </div>
    </div>
  );

  if (!enhancedShoppingCartTotal) return null;
  return (
    <Styles
      className={`ShoppingCartSummary ${className}`}
      shoppingCartTotals={enhancedShoppingCartTotal}
      formatter={defaultFormatter}
      title=""
    >
      <Subtotal />
      <Discount
        title={t.order.discount}
        formatter={{
          currency,
          symbol: `- ${currencySymbol}`
        }}
      />
      <ShippingCost title={t.order.sending} />
      {WITH_PACKAGE_COST && packageCost && !inCart ? renderPackageCost() : null}
      {WITH_LOYALTY ? <Points /> : null}
      {showTotal ? <Total title={t.order.total} /> : null}
      {showInfo ? (
        <p className="ShoppingCartSummary__package-cost__info">
          *{t.cart.includePackageCost}
        </p>
      ) : null}
    </Styles>
  );
};

ShoppingCartSummary.defaultProps = {
  className: ""
};

export default ShoppingCartSummary;
