import { useRouter } from "next/router";
import React from "react";

import Styles from "./EmptyCart.styles";
import { EmptyCartProps as Props } from "./EmptyCart.types";
import Button from "components/global/Button/Button";
import useI18n from "hooks/useI18n";

const EmptyCart: React.FC<Props> = props => {
  const { drawerCloseHandler } = props;
  const router = useRouter();
  const { push, pathname } = router;
  const isInCategories = pathname.includes("categories");
  const t = useI18n();
  const goToCategories = isInCategories
    ? drawerCloseHandler
    : () => push("/categories");

  return (
    <Styles className="EmptyCart">
      <p className="EmptyCart__title">{t.cart.emptyState}</p>
      <div className="EmptyCart__description">{t.cart.emptyDescription}</div>
      <Button className="EmptyCart__button" onClick={goToCategories}>
        {t.cart.addProducts}
      </Button>
    </Styles>
  );
};

EmptyCart.defaultProps = {};

export default EmptyCart;
