import styled from "styled-components";

import { ChooseAddressStyledProps as Props } from "./ChooseAddress.types";
import { DeleteAddressModalStyledProps as ModalProps } from "./ChooseAddress.types";
import InfoActionModal from "components/global/InfoActionModal/InfoActionModal";

const ChooseAddressStyled = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4.8rem;
  pointer-events: ${props => (props.disabled ? "none" : "initial")};
  width: 100%;

  .ChooseAddress {
    &__loading {
      svg {
        height: 2rem;
        width: 2rem;
        animation: rotate 0.6s linear infinite;
      }
    }

    &__list-item {
      .ListItem__description {
        color: var(--palette-gray-s0-l25);
        font-weight: 400;
        font-size: 1.4rem;
      }

      .ListItem__icon {
        & > svg {
          path {
            fill: var(--palette-black-s0-l10);
          }
        }

        &--active {
          & > svg {
            path {
              fill: var(--palette-primary);
            }
          }
        }
      }
    }
  }
`;

export const DeleteAddressModal = styled(InfoActionModal)<ModalProps>`
  path {
    fill: var(--palette-primary);
  }

  border-radius: 1.6rem;
  background-color: var(--palette-gray-s0-l95);

  .DeleteAddressModal {
  }
`;

export default ChooseAddressStyled;
