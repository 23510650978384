import { getMaxWidth } from "@sushicorp/utils";
import styled, { css } from "styled-components";

import { ProductMainStyledProps as Props } from "./ProductMain.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { tablet, mobile } = BREAKPOINTS;

const imageStyles = (props: Props) => {
  const { isPageStyle } = props;

  if (isPageStyle) {
    return css`
      width: 100%;
      border-radius: 1rem;
      aspect-ratio: 4/2;

      &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    `;
  }

  return css`
    height: 100%;

    &__img {
      border-radius: 1rem;
      object-fit: contain;
    }
  `;
};

const ProductMainStyled = styled.div<Props>`
  .ProductMain {
    &__content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      background-color: var(--white);
      height: 100%;

      @media (max-width: ${tablet}px) {
        justify-content: center;
        flex-direction: column;
        padding-top: 0;
        padding-bottom: 4rem;
        width: 100%;
        height: unset;
      }
    }

    &__wishlist-heart {
      padding: 0;
    }

    &__icons {
      display: flex;
      align-items: center;
    }

    &__preview-image {
      flex: 1 0 0;
      display: flex;
      justify-content: center;
      position: relative;
      align-items: flex-start;
      background-color: var(--palette-white);

      @media (max-width: ${tablet}px) {
        width: auto;
      }

      .Image {
        ${imageStyles}
      }

      & .ProductDetailsImage {
        position: sticky;
        top: 8rem;
      }
    }

    &__form {
      flex: 1 0.3 0;
      min-width: 38rem;
      height: max-content;
      background-color: var(--palette-gray-s0-l95);

      @media (max-width: 851px) {
        padding-top: 1.2rem;
        padding-left: 0;
      }

      @media (max-width: ${tablet}px) {
        min-width: 28rem;
      }

      @media (max-width: ${mobile}px) {
        width: 100%;
        min-width: auto;
      }
    }

    &__form--page {
      margin-left: 1.2rem;
      overflow: hidden;
      border-radius: 1rem;

      @media (max-width: ${tablet}px) {
        overflow: visible;
        margin-left: 0;
        margin-top: 1.2rem;
      }
    }

    &__info {
      padding: 2.8rem 1.6rem;
      background-color: var(--palette-gray-s0-l97);

      @media (max-width: ${mobile}px) {
        padding: 1.2rem 1.6rem;
      }
    }

    &__baseInfo-icons {
      display: flex;
      justify-content: space-between;

      @media (min-width: ${tablet + 1}px) {
        padding-right: 1.2rem;
      }

      .ProductModal {
        &__share {
          display: flex;
          margin-left: 1.6rem;
          height: 2.4rem;

          .Clickable {
            padding: 0;
          }
        }
      }

      @media (max-width: ${tablet}px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    &__description {
      &__text {
        font-size: 1.4rem;
        color: var(--palette-gray-s0-l50);
        padding: 1.6rem 0;
        font-family: inherit;
        white-space: pre-wrap;
        font-weight: 400;
        line-height: 2rem;
      }
    }

    &__comment-box {
      background-color: var(--palette-gray-s0-l95);
      padding-left: 1.6rem;
      padding-right: 1.6rem;
      margin-bottom: 1.6rem;

      .CommentBox__label {
        color: var(--palette-black-s0-l10);
        font-size: 1.2rem;
      }
    }

    &__wishlist-button {
      margin-left: auto;

      path {
        fill: var(--palette-primary);
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      width: 100% !important;

      @media (max-width: ${tablet}px) {
        position: fixed;
        display: flex;
        justify-content: space-between;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: var(--palette-white);
        padding: 1.2rem 0.4rem;
        z-index: 3;
        align-items: center;
        box-shadow: 0 -0.1rem 0.8rem 0.1rem var(--palette-gray-s0-l20-a20);
      }
    }

    &__actions--page {
      position: fixed;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: ${getMaxWidth()};
      padding: 1.2rem 1.6rem;
      background-color: var(--palette-white);
      z-index: 3;
      box-shadow: 0 -0.1rem 0.8rem 0.1rem var(--palette-gray-s0-l20-a20);

      @media (max-width: ${tablet}px) {
        justify-content: space-between;
      }
    }

    &__addToCart {
      width: 100%;
      max-width: 19.6rem;

      .Button {
        width: 100%;
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 2.4rem;
        padding: 1rem;

        @media (max-width: ${tablet}px) {
          flex-wrap: wrap;
          display: flex;
          align-self: center;
          padding: 0.8rem;
        }
      }
    }

    &__counter {
      margin-right: 3.2rem;

      & .Counter {
        &__button {
          width: 3.2rem;
          height: 3.6rem;
        }

        &__quantity {
          width: 2rem;
          font-size: 1.4rem;
        }
      }

      @media (max-width: ${tablet}px) {
        display: flex;
        align-self: center;
        order: -1;
      }

      @media (max-width: ${mobile}px) {
        margin-right: 0;
      }
    }
  }
`;

export default ProductMainStyled;
