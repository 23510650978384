import styled from "styled-components";

import { CounterStyledProps as Props } from "./Counter.types";

const CounterStyled = styled.div<Props>`
  display: inline-block;
  background-color: ${props =>
    props.disabled
      ? "var(--palette-gray-s0-l85)"
      : "var(--palette-gray-s0-l97-a10)"};
  pointer-events: ${props => (props.disabled ? "none" : "auto")};
  border-radius: 10rem;
  overflow: hidden;

  .Counter {
    padding-inline: 0.8rem;
    padding-block: 0.4rem;

    &__quantity {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${props =>
        props.disabled
          ? "var(--palette-gray-l85)"
          : "var(--palette-gray-s0-l97-a10)"};
      color: ${props =>
        props.disabled ? "var(--palette-white)" : "var(--palette-primary)"};
      border-radius: 0.4rem;
      padding: 0 0.6rem;
      font-weight: 600;
      margin: 0 0.2rem;
      font-size: 1.2rem;
    }

    &__button {
      padding: 0.8rem;
      height: 2.8rem;
      border: 0;
      border-radius: 8rem;
      box-shadow: 0 0 0 0;
      background-color: var(--palette-primary);

      path {
        fill: var(--palette-white);
      }

      &:disabled {
        border: 0;
        background-color: var(--palette-gray-s0-l74);
      }
    }
  }
`;

export default CounterStyled;
