import styled from "styled-components";

import { AddNewRecordButtonStyledProps as Props } from "./AddNewRecordButton.types";
import Button from "components/global/Button/Button";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { desktop } = BREAKPOINTS;

const AddNewRecordButtonStyled = styled(Button)<Props>`
  &&.AddNewRecordButton {
    background-color: var(--palette-primary);
    border-radius: 8rem;
    padding: 0.8rem 1.6rem;
    justify-content: flex-start;
    color: var(--palette-white);
    margin-top: 1.2rem;
    margin-bottom: 3.2rem;

    path {
      fill: var(--palette-white);
    }

    &:hover:not([disabled]) {
      transition: 0.8s ease;

      path {
        fill: ${props =>
          props.color === "primary-light"
            ? "var(--palette-primary)"
            : "var(--palette-white)"};
      }

      .AddNewRecordButton__value {
        color: ${props =>
          props.color === "primary-light"
            ? "var(--palette-primary)"
            : "var(--palette-white)"};
      }
    }
  }

  .AddNewRecordButton {
    &__value {
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 600;
      flex: 1;
      color: var(--palette-white);
      display: block;

      @media (max-width: ${desktop}px) {
        display: none;
      }

      &--mobile {
        display: block;
        font-size: 1.4rem;

        @media (min-width: ${desktop}px) {
          display: none;
        }
      }
    }
  }
`;

export default AddNewRecordButtonStyled;
