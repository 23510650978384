import { useCatalogues, useShoppingCart } from "@sushicorp/contexts";
import { useVendors } from "@sushicorp/contexts";
import { buildArtisnHeaders } from "@sushicorp/services";
import { getBenefitProductId } from "@sushicorp/utils";
import { redeemCoupon } from "artisn/shopping-cart";
import { Badge } from "artisn-ui-react";
import dayjs from "dayjs";
import React, { useState, useMemo } from "react";

import Styles from "./RedeemCoupon.styles";
import { RedeemCouponProps as Props } from "./RedeemCoupon.types";
import RedeemCouponInput from "./RedeemCouponInput/RedeemCouponInput";
import CouponsModal from "components/coupons/CouponsModal/CouponsModal";
import Button from "components/global/Button/Button";
import ProductModal from "components/global/ProductModal/ProductModal";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import useI18n from "hooks/useI18n";
import { createSuccessNotification } from "utils/notifications.utils";

import Coupon from "../../../../public/assets/images/coupon.svg";

const { ACCOUNT_ID } = CONSTANTS.ARTISN;
const { API_URL } = CONSTANTS.API;

const RedeemCoupon: React.FC<Props> = props => {
  const { className } = props;
  const t = useI18n();
  const { selectedCatalogueId: catalogueId } = useCatalogues();
  const { selectedVendorId: vendorId } = useVendors();
  const { isAnonymous = false, uid } = useAuth();
  const [opened, setOpened] = useState(false);
  const [redeemCouponError, setRedeemCouponError] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const { benefitsWallet, temporalBenefit } = useShoppingCart();
  const { benefits } = benefitsWallet ?? {};
  const availableBenefits = useMemo(() => {
    if (!benefits) return [];
    return benefits.filter(item => dayjs().isBefore(item.expirationDate));
  }, [benefits]);
  const benefitsLength = useMemo(
    () => availableBenefits?.length,
    [availableBenefits]
  );
  const [isOpenModal, setIsOpenModal] = useState(false);
  const productId = getBenefitProductId(temporalBenefit);

  const redeemHandler = async (code: string) => {
    if (code.trim() === "") return;
    if (!uid) return;

    try {
      setRedeemCouponError("");
      const headers = await buildArtisnHeaders();
      const config = {
        apiURL: API_URL,
        accountId: ACCOUNT_ID,
        code,
        anonymous: isAnonymous,
        customerId: uid,
        channelId: +catalogueId,
        vendorId
      };
      await redeemCoupon(config, code, headers);
      setCouponCode("");
      createSuccessNotification(t.coupons.redeemSuccess);
    } catch (e) {
      console.log({ e: e.message });
      if (e.message.includes("Cupón ya canjeado")) {
        return setRedeemCouponError(e.message);
      }
      setRedeemCouponError(`${t.errors.coupons.invalid}`);
    }
  };

  const openHandler = () => {
    setRedeemCouponError("");
    setOpened(prev => !prev);
  };

  const onClickModal = () => {
    setIsOpenModal(true);
  };

  const buttonNode = (
    <Button
      color="primary"
      className="RedeemCoupon__button-wallet"
      onClick={() => openHandler()}
    >
      <Coupon />
    </Button>
  );

  return (
    <Styles className={`RedeemCoupon ${className}`}>
      <div className="RedeemCoupon__container">
        <RedeemCouponInput
          className="RedeemCoupon__input"
          couponCode={couponCode}
          setCouponCode={setCouponCode}
          onRedeem={redeemHandler}
        />
        {benefitsLength ? (
          <Badge
            value={benefitsLength.toString()}
            className="RedeemCoupon__badge"
          >
            {buttonNode}
          </Badge>
        ) : (
          <div className="RedeemCoupon__badge">{buttonNode}</div>
        )}
      </div>
      {redeemCouponError ? (
        <p className="RedeemCoupon__redeem-error">{redeemCouponError}</p>
      ) : null}
      <CouponsModal
        opened={opened}
        benefits={availableBenefits}
        redeemCouponError={redeemCouponError}
        redeemHandler={redeemHandler}
        openProductModal={onClickModal}
        setRedeemCouponError={setRedeemCouponError}
        onClose={() => setOpened(prev => !prev)}
      />
      <ProductModal
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        productId={productId}
      />
    </Styles>
  );
};

RedeemCoupon.defaultProps = {
  className: ""
};

export default RedeemCoupon;
