import CONSTANTS from "config/constants";

const { TITLE } = CONSTANTS;

const es = {
  title: "Chef Choy",
  home: {
    metaTitle: TITLE,
    metaDescription:
      "Tus sabores favoritos en el menú sushi de Chef Choy, podrás elegir toda variedad de platos y bocados preparados con los ingredientes más frescos.",
    card: {
      download: "Descarga",
      ourApp: "Nuestra App",
      downloadNow: "Descargar ahora",
      useYour: "Usa tus",
      couponsOf: "cupones de",
      discount: "descuento",
      discounts: "descuentos",
      coupons: "Cupones",
      enjoy: "Disfruta de",
      specials: "especiales"
    },
    menu: "Ver menú",
    promotions: "Promociones",
    superPromotions: "Super promotions"
  },
  selectAddress: {
    deliverIn: "Entregar en",
    pickupAt: "Recoger en",
    selectAddress: "Selecciona una dirección",
    selectStore: "Selecciona una tienda",
    currentLocation: "Usar ubicación actual",
    nearestAddress: "Escoger dirección más cercana",
    storeNearest: "Seleccionar tienda más cercana",
    addNewAddress: "Añadir nueva dirección"
  },
  productsSearch: {
    products: "Productos",
    metaTitle: "Descubre",
    recentSearches: "Búsquedas recientes",
    clearHistory: "Limpiar historial",
    popularSearches: "Búsquedas populares",
    results: "Resultados",
    resultsOf: "Resultados de",
    discoverProducts: "Descubre más productos",
    noResultsFor: "No hay resultados para",
    recommended: "Recomendados",
    seeMenu: "Ver menú completo",
    noFind: "¿No encontraste lo que querías?",
    errorLoading:
      "Hubo un error al cargar la página, intenta recargando la página o vuelve luego"
  },
  loyalty: {
    metaTitle: "Lealtad"
  },
  coupons: {
    metaTitle: "Cupones",
    detailTitle: "Cupones",
    title: "Cupones digitales",
    apply: "Aplicar",
    applyInCart: "Aplicar cupón en el carrito",
    available: "Disponibles para canjear",
    noCoupons: "No existe billetera de cupones disponibles para tu usuario.",
    enterWithYourAccount:
      "Ingresa con tu cuenta para poder agregar tus códigos",
    signIn: "Iniciar sesión",
    registerAndGetBenefits: "Registrate y obtén beneficios",
    getCoupons:
      "Obtén cupones de descuento, guarda tus datos para compras futuras y mucho más",
    enter: "Ingresa con tu cuenta",
    couponWallet: "Billetera de cupones",
    couponsBenefits: "Con tus cupones podrás obtener descuentos y beneficios.",
    addNewCoupon: "Ingresa tu cupón",
    addCoupons: "Añade cupones",
    couponsInfo:
      "Con tus cupones podrás obtener descuentos y muchos más beneficios",
    noCouponsToApply: "No tienes cupones para aplicar",
    addCoupon: "Ingresa tu cupón",
    validUntil: "Válido hasta",
    redeemSuccess: "Cupón redimido con éxito"
  },
  profile: {
    profile: "Perfil",
    metaTitle: "Usuario",
    completeProfile: "Completar perfil",
    greeting: "Hola",
    welcome: "Bienvenido",
    edit: "Editar cuenta",
    enjoyBenefits:
      "Inicia sesión para disfrutar de los beneficios de tener una cuenta",
    orderHistory: {
      orderNow: "Ordena ahora",
      purchaseHistory: "Historial de ordenes",
      firstPurchase: "Realiza tu primera compra",
      explore: "Explora",
      exploreAllProducts: "Explora todos nuestros productos y categorías",
      date: "Fecha",
      pastOrders: "Pedidos anteriores",
      filters: {
        last15Days: "últimos 15 días",
        lastMonth: "último mes",
        last6Months: "últimos 6 meses"
      }
    },
    navigation: {
      orderHistory: "Historial de compra",
      coupons: "Cupones",
      deliveryAddress: "Dirección de entrega",
      stores: "Locales",
      billing: "Datos de facturación",
      notifications: "Notificaciones",
      legalTerms: "Términos legales",
      support: "Contactar con soporte",
      signOut: "Cerrar sesión"
    },
    notifications: {
      title: "Notificaciones",
      description: "Acepto recibir correos electrónicos",
      consult: "Consulta el estado en",
      orders: "Mis órdenes",
      receiveNotifications: "Recibir notificaciones del estado de mis pedidos"
    },
    address: {
      addNewAddress: "Añadir nuevas direcciones",
      add: "Añadir",
      house: "Casa",
      office: "Oficina",
      others: "Otros",
      predetermined: "Predeterminado",
      findAddress: "Encontrar dirección en el mapa",
      findYourAddress: "Encuentra tu dirección",
      placeHolderSearch: "Buscar",
      confirmLocation: "Confirmar ubicación",
      currentPosition: "Tu posición actual",
      noCoverage: "Fuera de cobertura",
      noCoverageDescription:
        "Tu dirección está fuera de cobertura, intenta cambiarla",
      editLocation: "Editar ubicación",
      fillAddress: "Completa tu dirección",
      noResultsFound: "No se encontraron resultados",
      saveAddress: "Guarda tus direcciones",
      descriptionEmptyAddresses:
        "Agrega una dirección de entrega para realizar tus compras.",
      deleteAddress: "¿Deseas proceder a eliminar?",
      deleteAddressDescription:
        "Una vez eliminado no podrás recuperar esta información",
      form: {
        saveAddress: "Guardar dirección",
        labelName: "Etiqueta",
        house: "Casa",
        work: "Trabajo",
        others: "Otros",
        usePredetermined: "Guardar como predeterminada",
        label: {
          mainStreet: "Calle primaria",
          houseNumber: "Número de vivienda",
          secondaryStreet: "Calle secundaria",
          reference: "Referencia",
          phoneNumber: "Número de teléfono para confirmación"
        },
        placeholder: {
          mainStreet: "Av Orellana",
          houseNumber: "E3 40",
          secondaryStreet: "Av Amazonas",
          reference: "Metropolitan Park / Ecuanimsa",
          phoneNumber: "99 999 9999",
          others: "Mi casa",
          comment: "Indicaciones al momento de entregar en esta dirección"
        }
      }
    },
    stores: {
      title: "Locales",
      open: "Abierto",
      closed: "Cerrado",
      address: "Dirección",
      phone: "Teléfono",
      opens: "Abre",
      closing: "Cierra",
      schedules: "Horarios"
    },
    support: {
      title: "Contactar con soporte",
      description:
        "Contáctate con nuestros canales de soporte para recibir ayuda sobre tu caso.",
      email: "Correo electrónico",
      whatsapp: "Whatsapp",
      phone: "Teléfono"
    },
    user: {
      identityCard: "Cédula",
      passport: "Pasaporte",
      ruc: "RUC",
      deleteAccount: "Eliminar cuenta",
      saveChanges: "Guardar cambios",
      connectGoogle: "Conectar Google",
      connectFacebook: "Conectar Facebook",
      disconnectGoogle: "Desconectar de Google",
      disconnectFacebook: "Desconectar de Facebook",
      connectNetworks: "Conecta tus cuentas con tus redes",
      leavingUs: "¿Por qué nos dejas?",
      tellUsMore: "Cuéntanos un poro más",
      byeDifficult: "Decir adios es difícil",
      unsubscribeDescription1: "Nos apena verte partir.",
      unsubscribeDescription2: "Cuéntanos por qué decides dejarnos.",
      unsubscribeDescription3:
        "Una vez que elimines tu cuenta éstas serán las consecuencias",
      unsubscribeWarning1: "No podrás volver a recuperar tu cuenta.",
      unsubscribeWarning2:
        "Todos tus registros y datos guardados serán eliminados.",
      unsubscribeWarning3:
        "Deberás crear una nueva cuenta si lo necesitas y deberás guardar nuevamente tus datos.",
      reason1: "Tuve una mala experiencia",
      reason2: "No hay cobertura en mi zona",
      reason3: "Alguien más está utilizando mi cuenta",
      reason4: "Consume muchos datos",
      reason5: "No uso la aplicación",
      unlinkAccount: "Desvincular cuenta",
      sureUnlinkAccount: "¿Estás seguro de desvincular tu cuenta?",
      dataMayBe:
        "Es posible que se pierdan algunos datos al realizar esta acción",
      maintainData: "Mantener datos",
      enterEmailConfirm: "Ingresa tu correo para confirmar",
      noRecoverData:
        "Una vez eliminada tu cuenta no podrás recuperar tus datos.",
      maintainAccount: "Mantener cuenta",
      deleteForever: "Eliminar para siempre mis datos",
      keepEnjoying: "Sigue los rodillos",
      thankYouTrust: "Sigue disfrutando de Chef Choy.",
      accountDeleted: "Tu cuenta ha sido eliminada",
      lookAgain: "Esperamos volverte a ver",
      errors: {
        noDeleteAccount:
          "No fue posible eliminar la cuenta. Inténtalo mas tarde",
        phone: "El teléfono ingresado no es válido",
        birthdate: "El usuario debe ser mayor que 18 años"
      }
    }
  },
  billing: {
    searchBillingInformation: "Buscar datos de facturación",
    billingInformation: "Datos de facturación",
    addBillingInformation: "Añadir nuevos datos de facturación",
    editBillingInformation: "Editar datos de facturación",
    newBillingInformation: "Nuevos datos de facturación",
    deleteBillingInformation:
      "Una vez eliminado no podrás recuperar esta información",
    questionDeleteBillingInformation: "¿Deseas proceder a eliminar?",
    saveYourData: "Guarda tus datos",
    saveYourDataDescription:
      "Agrega tus datos de facturación para realizar tus compras",
    youWantPreviousData:
      "¿Quieres utilizar los datos de tus anteriores compras?",
    importData: "Importar datos",
    continueWithoutData: "Continuar sin importar datos"
  },
  terms: {
    legalTerms: "Términos legales",
    privacy: "Políticas y privacidad",
    data: "Ley de protección de datos",
    dataProtection: {
      title: "Te invitamos a revisar nuestras nuevas políticas y aceptarlas",
      description: {
        first:
          "Manejamos la información que nos brindas para gestionar nuestras iniciativas comerciales y mantener una relación cercana con nuestros clientes.",
        second: (email: string) =>
          `Queremos que sepas que tienes el derecho de acceder, actualizar, revocar, eliminar y oponerte al tratamiento de tus datos. Si deseas ejercer alguno de estos derechos, no dudes en enviarnos un correo electrónico a: ${email}. Estaremos encantados de ayudarte en lo que necesites.`
      }
    }
  },
  navigation: {
    shopping: "Comprar",
    discover: "Descubre",
    loyalty: "Lealtad",
    coupons: "Cupones",
    profile: "Menu"
  },
  navbar: {
    enter: "Ingresar",
    recentSearches: "Búsquedas recientes",
    cleanHistory: "Limpiar historial",
    popularSearches: "Búsquedas populares",
    results: "resultados"
  },
  catalogues: {
    delivery: "Entrega",
    pickup: "Retiro",
    other: "Otro"
  },
  geo: {
    useCurrentLocation: "Usar ubicación actual",
    selectNearbyStore: "Seleccionar tienda más cercana"
  },
  common: {
    enter: "Ingresar",
    whatDoYouProvokeToday: "¿Qué te provoca hoy?",
    pickupIn: "Recoger en",
    deliveryIn: "Entregar en",
    selectAddress: "Escoger dirección más cercana",
    selectStore: "Selecciona una tienda",
    search: "Buscar",
    cart: "Carrito",
    edit: "Editar",
    preferences: "Preferencias",
    workflows: "Modalidad de compra",
    apply: "Aplicar",
    accept: "Aceptar",
    buyingIn: "Comprando en",
    order: "Orden",
    help: "Ayuda",
    catalogue: "Catálogo",
    termsAndConditions:
      "He leído y acepto políticas de privacidad, términos y condiciones",
    preTerms: "He leído y acepto los",
    midTerms: "Términos de uso de Chef Choy",
    postTerms: "para el uso de esta plataforma",
    copyright: "ⓒ Chef Choy. Todos los derechos reservados.",
    orderNow: "Ordena ahora",
    company: "Trade",
    see: "Ver",
    seeNow: "Ver ahora",
    repeatOrder: "Repetir pedido",
    repeat: "Repetir",
    state: "Estado",
    details: "Detalles",
    add: "Añadir",
    delete: "Eliminar",
    change: "Cambiar",
    auth: {
      signInWithGoogle: "Acceder con Google",
      signInWithFacebook: "Acceder con Facebook",
      signInWithEmail: "Acceder con correo electrónico",
      signIn: "Iniciar sesión",
      signUp: "Crear cuenta",
      forgotPassword: "¿Olvidaste tu contraseña?",
      recoverPassword: "Recuperar contraseña",
      weRecognizeYou: "¡Te reconocemos!",
      importingData: "Estamos importando tus datos."
    },
    goToProduct: "Ir al producto",
    backTo: "Volver",
    summary: "Resumen",
    forms: {
      label: {
        email: "Correo electrónico",
        password: "Contraseña",
        name: "Nombres",
        lastName: "Apellidos",
        phone: "Número de contacto",
        birth: "Fecha de nacimiento",
        payWith: "¿Con cuánto vas a pagar?",
        comment: "Agrega una nota a este pedido",
        documentType: "Tipo de identificación",
        documentNumber: "Número de identificación",
        businessName: "Razón social",
        nameAndLastName: "Nombre y apellido",
        useSaveDirection: "¿Usar dirección guardada?",
        useLikeDefault: "¿Usar como predeterminado?",
        selectAnAddress: "Selecciona una dirección",
        address: "Dirección",
        selectAddress: "Seleccionar dirección"
      },
      placeholder: {
        email: "juanperez@gmail.com",
        password: "*************",
        name: "David",
        lastName: "Fonseca",
        phone: "99 999 2323",
        comment: "Comentarios",
        address: "Avenida Orellana 130",
        documentNumber: "ZE001355"
      },
      errors: {
        invalidPhone: "El teléfono ingresado no es válido",
        emailNotMatch: "El correo no coincide con el registrado",
        alreadyImported:
          "Parece que ya hemos importado tus datos. Ingresa a tu cuenta para continuar."
      }
    },
    saveBillingData: "Guardar datos de facturación",
    goBack: "Atrás",
    continue: "Continuar",
    cancel: "Cancelar",
    send: "Enviar",
    resend: "Volver a enviar",
    and: "y",
    from: "de",
    to: "a"
  },
  footer: {
    termsAndConditions: "Términos y condiciones",
    privacyPolicy: "Políticas de privacidad",
    faq: "Preguntas frecuentes",
    about: "Trabaja con nosotros",
    location: "¿Dónde estamos?",
    reservations: "Contáctanos",
    developer: "Desarrollado por"
  },
  order: {
    today: "Hoy",
    currentOrder: "Orden en curso",
    completedOrder: "Orden finalizada",
    processing: "Estamos procesando tu pedido",
    success: "Orden procesada exitosamente",
    error: "Hubo un problema al procesar tu orden",
    tryAgain: "Volver a intentarlo",
    emptyCart: "Aún no has agregado productos en tu carrito",
    exploreMenuDescription: "Explora nuestro menú y ¡Agrega productos!",
    exploreMenu: "Explorar menú",
    transactionInformation: "Información de transacción",
    qualifyOrder: "Califica tu orden",
    qualify: "Calificar",
    howOrder: "¿Como estuvo tu orden?",
    order: "Orden",
    qualifiedOrder: "Orden calificada",
    noExistOrder: "No existe la orden en esta sesión",
    noExistOrderNavigator:
      "No existe información para la orden especificada en este navegador",
    estimatedTime: "Hora estimada de entrega",
    negativeFeedback: "Coméntanos que te ha salido mal",
    positiveFeedback: "Coméntanos que te ha gustado",
    wentWrong: "¿Algo salió mal?",
    whatLike: "¿Que fué lo que más te gustó?",
    thankFeedback: "Gracias por tus comentarios",
    savedTime: "Ahorra tiempo en tus compras",
    savedTimeDescription:
      "Ingresa con tu cuenta y realiza tus compras sin volver a llenar tus datos",
    orderNumber: "Nro. de orden",
    orderTime: "Fecha y hora",
    orderCode: "Código de pedido",
    reference: "Referencia",
    state: "Estado",
    subTotal: "Subtotal",
    summary: "Resumen del pago",
    sending: "Envío",
    total: "Total",
    taxes: "Impuestos",
    discount: "Descuentos",
    updateOrder: "Actualizar",
    destination: "Lugar de entrega",
    store: "Tienda",
    paymentMethod: "Método de pago"
  },
  checkout: {
    title: "Checkout",
    wantBuyFast: "Compra más rápido la próxima vez",
    signInWithYourAccount: "Ingresa con tu cuenta",
    shippingAddress: "Dirección de la compra",
    pay: "Pagar",
    completeYourShippingAddress: "Completa tu dirección de envío",
    completeYourBillingData: "Completa tus datos de facturación",
    billingData: "Datos de facturación",
    endConsumer: "Consumidor final",
    addBillingData: "Añadir datos de facturación",
    anonymousForm: {
      purchaseData: "Información de la compra",
      useSameDataForBilling: "Utilizar los mismos datos para facturación"
    },
    paymentMethods: "Métodos de pago",
    cash: "Pago en efectivo",
    card: "Pago con tarjeta",
    payWithExactChange: "Pagar con el valor exacto",
    change: "Cambio:",
    moreThan50: "Montos mayores a $50 deben facturar",
    identityBox: "Número de identificación",
    processingPayment: {
      title: "Ahora procesamos tu  pago...",
      subtitle: "Este proceso podría tardar unos momentos..."
    }
  },
  errors: {
    paymentez: {
      cannotContinue: "No se pudo continuar con el pago",
      errorWhilePayOrder: "Ocurrió un error al momento de realizar el pago",
      failure: "Su intento de pago ha fallado",
      refused: "Pago rechazado",
      notTypeOfSuccessResponse: "Tu tarjeta no es válida",
      saveCard: "Tuvimos un problema al intentar guardar su tarjeta",
      card: "No cuenta con una tarjeta a ser guardada",
      store: "No tiene una tienda seleccionada",
      transactionId: "No encontramos el identificador de la transacción",
      otpPending:
        "El código OTP ingresado es inválido, por favor inténtelo de nuevo",
      otpFailure: "Ocurrió un problema al verificar su tarjeta",
      unsupportedIssuer: "El emisor seleccionado no es compatible",
      unsupportedType: "El tipo seleccionado no es compatible",
      invalidFields: "Los campos son inválidos",
      invalidExpirationDate: "La fecha de expiración es inválida"
    },
    amountIsNotEnough: "El monto no es suficiente",
    unsavedData: "No se pudo guardar tus datos",
    completeYourDataProfile: "Completa tus datos nuevamente en tu perfil",
    registrationError: "Hubo un error al registrarse",
    alreadyAssociated: "Este correo ya está asociada a otra cuenta de usuario",
    firebase: {
      auth: {
        wrongPassword: "Los datos son inválidos",
        userNotFound:
          "No existe un registro de usuario con el correspondiente identificador provisto",
        tooManyRequests:
          "Demasiadas solicitudes. El acceso a esta cuenta se ha inhabilitado temporalmente",
        emailAlreadyInUse: "El correo electrónico provisto ya está en uso",
        networkRequestFailed:
          "Ha ocurrido un error de conexión, intenta nuevamente",
        operationNotAllowed:
          "El proveedor está deshabilitado en tu proyecto de firebase",
        accountExistsWithDifferentCredential:
          "An account already exists with the same email address but different sign-in credentials",
        invalidActionCode: "El link usado es inválido",
        googleProvider:
          "No se encontró ningún usuario con el proveedor de Google",
        facebookProvider:
          "No se encontró ningún usuario con el proveedor de Facebook"
      }
    },
    geo: {
      noCoordinates: "No se encontraron coordenadas"
    },
    stores: {
      noStores: "No se encontraron tiendas en la ubicación seleccionada",
      storeClosed: "La tienda se encuentra cerrada"
    },
    fallback: "La petición falló, inténtelo nuevamente",
    common: "Ups... Algo pasó. Vuelve a intentarlo.",
    productError: "Hubo un error en los productos",
    general: "Oops ocurrió un error",
    back: "Volver",
    acceptTerms: "Debes aceptar los términos de uso antes de continuar",
    coupons: {
      invalid: "El cupón es inválido",
      noProducts: "El beneficio no aplica porque el carrito está vacío",
      exceedsAmount:
        "No se puede aplicar el beneficio porque el monto del descuento es mayor al subtotal del carrito"
    },
    card: {
      failAddCard: "No se pudo agregar la tarjeta",
      maxCardLimit: (maxCards: number) =>
        `Solo es posible tener ${maxCards} tarjetas activas`,
      overCardLimit: "Límite de tarjetas activas superado",
      noSelectedCard: "Debes seleccionar una tarjeta para continuar"
    },
    checkout: {
      insufficientFunds: "El monto es insuficiente",
      missingData: (data: string[]) =>
        `No se pudo realizar el pedido debido a la falta de datos: ${data.join(
          " "
        )}`,
      invalidBilling:
        "Los datos de facturación ingresados en el formulario no son válidos",
      noShippingAddress: "Debes seleccionar una dirección de envío",
      timeout: {
        title: "Estamos demorando en actualizar tu orden",
        subtitle: "Puede que tu orden ya esté procesada.",
        body: "Espera unos minutos y verifica tu historial de órdenes para confirmar si tu orden fue creada",
        button: "Verificar historial de órdenes"
      }
    },
    maxAddressLimit: (maxAddress: number) =>
      `Solo es posible tener ${maxAddress} direcciones activas`,
    maxBillingLimit: (maxBilling: number) =>
      `Solo es posible tener $${maxBilling} datos de facturación activos`,
    overAddressLimit: "Límite de direcciones activas superado",
    updateAddress: "Hubo un error al actualizar la dirección",
    deleteAddress: "Hubo un error al eliminar la dirección",
    overBillingLimit: "Límite de datos de facturación activos superado",
    updateBilling: "Hubo un error al actualizar los datos de facturación",
    deleteBilling: "Hubo un error al eliminar los datos de facturación",
    showMapAddress: "No se pudo mostrar la dirección en el mapa",
    failSearch: "Hubo un error al traer los resultados de la búsqueda",
    fetchAddress: "Hubo un error al traer la dirección buscada",
    password: {
      changeSuccess: "Cambio exitoso",
      updateSuccess: "Contraseña actualizada exitosamente",
      updateFail: "No se pudo actualizar la contraseña"
    },
    minOrderAmount: (minAmount: number) =>
      `Agrega artículos con un valor de al menos $${minAmount} para realizar tu pedido`,
    minOrder: "Tu pedido no cumple con el monto mínimo",
    maxOrderAmount: (maxAmount: number) =>
      `Agrega artículos hasta un valor de $${maxAmount} para realizar tu pedido`,
    maxOrder: "Tu pedido excede el monto máximo",
    unsupportedProvider: (provider: any) =>
      `No aceptamos pagos del proveedor ${provider}`,
    pendingFetchError: "Error al consultar si hay pedidos pendientes",
    user: {
      saveError: "Hubo un error al guardar los datos del usuario",
      updateError: "Hubo un error al actualizar los datos del usuario"
    },
    documentIsEmpty: "El documento no puede estar vacío"
  },
  productDetail: {
    utensils: "Utensilios",
    onlyCanDecrease: "No puedes incrementar, solo disminuir"
  },
  success: {
    resendEmail: "Correo reenviado exitosamente",
    login: "Ingreso exitoso"
  },
  signIn: {
    signInUse: "Ingresar utilizando",
    signInWithEmail: "Inicia sesión con tu correo electrónico",
    haveNotAnAccountYet: "¿Aún no tienes cuenta?",
    title: "Ingresa a tu cuenta",
    signInUseEmail: "Ingresar tu correo electrónico"
  },
  signUp: {
    signUpUse: "Registrarse utilizando",
    signUpWithEmail: "O utilizando tu correo electrónico",
    alreadyHaveAnAccount: "¿Ya tienes una cuenta?",
    title: "Disfruta de los beneficios de tener una cuenta",
    acceptThe: "Acepto los",
    terms: "términos y condiciones",
    privacy: "políticas de privacidad",
    byCompany: "de Chef Choy"
  },
  magicLink: {
    description: "Enviaremos un link para que puedas ingresar"
  },
  checkEmailVerification: {
    title: "Hemos enviado un email a:",
    instructions:
      "Ahí encontrarás un enlace que te permitirá ingresar a tu cuenta",
    help: "¿No has recibido el email de ingreso?",
    tryAgain: "Enviar nuevamente"
  },
  signUpUser: {
    title: "Solo un paso más",
    subtitle: "Es importante que completes tus datos para crear tu cuenta",
    modal: {
      title: "Toma en cuenta lo siguiente",
      description:
        "Si continuas vas a perder los datos asociados a tu identificador"
    }
  },
  repeatOrder: {
    title: "¿Con ganas de revivir momentos?",
    repeatOrder: "Repetir último pedido",
    placeholder: {
      description: "Repetir tu orden del",
      order: "Orden"
    }
  },
  recover: {
    title: "Ingresa tu correo electrónico para recuperar tu contraseña",
    doNotReceiveEmail: "¿No recibiste el correo?",
    retryIn: "Volver a intentar en ",
    wrongEmail: "¿Te equivocaste de correo?",
    editEmail: "Editar email",
    checkSpam:
      "Tu correo fue reenviado nuevamente, si no lo consigues intenta buscando en la carpeta de spam.",
    weSendEmail: "Hemos enviado un correo a",
    checkEmail: "verifica e inicia sesión."
  },
  password: {
    title: "La contraseña debe contener:",
    validation: {
      number: "Al menos un número",
      specialChar: "Al menos un carácter especial",
      capitalLetter: "Al menos una letra mayúscula",
      minLength: "Al menos ocho caracteres"
    }
  },
  cart: {
    cart: "Carrito",
    add: "Agregar",
    share: "Compartir",
    goCheckout: "Ir a pagar",
    emptyCart: "Vaciar carrito",
    emptyState: "Aún no tienes productos en tu carrito",
    emptyDescription: "Agrega productos para iniciar una nueva compra",
    addProducts: "Agregar productos",
    productNotAvailable: "Este producto no está disponible",
    addedProduct: "Has añadido",
    showCart: "Ver carrito",
    packagingCosts: "Costo de empaque",
    includePackageCost: "No incluye costo de empaque",
    couponLoginTitle: "Regístrate para obtener beneficios",
    couponLoginDescription:
      "Al registrarte podrás hacer uso de nuestros cupones y obtendrás muchos beneficios."
  },
  moveCart: {
    title: "Los productos en tu carrito",
    message: "Al cambiar tu dirección podrías perder algunos de tus productos.",
    confirm: "Crear nuevo carrito",
    cancel: "Mantener mi carrito",
    success: "Se ha movido el carrito a la nueva ubicación"
  },
  product: {
    writeComment: "Escribe un comentario",
    aComment: "Un comentario"
  },
  notifications: {
    outCoverage: "Estás fuera de cobertura",
    changeLocationContinue: "Intenta cambiar tu ubicación para continuar",
    changeLocation: "Cambiar de ubicación"
  },
  fourZeroFour: {
    title: "Error 404",
    description: "¡Página no encontrada!",
    button: "Ir a inicio"
  },
  shoppingCart: {
    maximum: "Máximo",
    utensil: "utensilio",
    sauce: "salsa",
    findExtraProducts:
      "*Si desea salsas adicionales, puede adquirirlas en la sección de",
    extras: "EXTRAS"
  },
  paymentezModal: {
    verifyCard: "Verifica tu tarjeta",
    verifyCode: "Verificar código"
  },
  card: {
    selectCard: "Selecciona una tarjeta",
    myCard: "Mi tarjeta",
    myCards: "Mis tarjetas",
    addCard: "Nueva tarjeta",
    changeCard: "Cambiar tarjeta",
    listTitle: "Guarda tus tarjetas",
    listDescription:
      "Agrega una tarjeta para realizar tus compras aún más rápido.",
    cardHolder: "Nombre en tarjeta",
    cardHolderPlaceholder: "Juan Pérez",
    cardNumber: "Número de tarjeta",
    expirationDate: "Fecha de expiración",
    cvv: "CVV",
    saveCard: "Guardar tarjeta"
  }
};

export default es;
