import { Providers } from "artisn/analytics";
import { initializeApp } from "firebase/app";
import { getAuth as firebaseGetAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

export const getENVs = (() => {
  const env = process.env.NEXT_PUBLIC_ENV ?? "develop";

  return {
    develop: {
      apiKey: "AIzaSyA9-gZ2saX26QLtn9DZsIKxld94FXQmjMg",
      authDomain: "sc-chef-choy-dev.firebaseapp.com",
      databaseURL: "https://sc-chef-choy-dev-default-rtdb.firebaseio.com",
      projectId: "sc-chef-choy-dev",
      storageBucket: "sc-chef-choy-dev.appspot.com",
      messagingSenderId: "882363712300",
      appId: "1:882363712300:web:8b193950fe6b285e9ca289",
      measurementId: "G-Y75M3844M7",
      // TODO: replace maps api key later
      mapsApiKey: "AIzaSyD-o9htbV86JDu0mMka5nhz3vuYbBR_oAg",
      facebookPixelId: "661727596142194"
    },
    production: {
      apiKey: "AIzaSyDEKU1Y0NMpW9tkWxBcKeqBdkQZEFVn9o0",
      authDomain: "sc-chef-choy-prod.firebaseapp.com",
      databaseURL: "https://sc-chef-choy-prod-default-rtdb.firebaseio.com",
      projectId: "sc-chef-choy-prod",
      storageBucket: "sc-chef-choy-prod.appspot.com",
      messagingSenderId: "1008506378389",
      appId: "1:1008506378389:web:ead49a2988b6a01fa9239c",
      measurementId: "G-EXJ1E50172",
      // TODO: replace this later because this credentials is for dev
      mapsApiKey: "AIzaSyD-o9htbV86JDu0mMka5nhz3vuYbBR_oAg",
      facebookPixelId: "384745871220061"
    }
  }[env];
})();

const firebaseConfig = {
  projectId: getENVs?.projectId ?? "",
  apiKey: getENVs?.apiKey ?? "",
  authDomain: `${getENVs?.projectId}.firebaseapp.com`,
  databaseURL: getENVs?.databaseURL,
  appId: getENVs?.appId
};

export const app = initializeApp(firebaseConfig);
export const getAuth = firebaseGetAuth(app);
export const db = getFirestore(app);

export const facebookProvider = new Providers.FacebookPixel({
  name: "facebook",
  trackingId: getENVs?.facebookPixelId ?? "",
  version: "2.0"
});

export const googleProvider = new Providers.GoogleAnalytics({
  name: "google",
  trackingId: getENVs?.measurementId ?? ""
});
