import Bugsnag from "@bugsnag/js";

// Common utility functions

import CONSTANTS from "config/constants";

const { API } = CONSTANTS;
const { MOCK_SERVICES } = API;

// This value should not be modified if you want to disable mocks
// To disable mocks, go to config/constants.ts
export const shouldMock =
  process.env.NEXT_PUBLIC_ENV === "production" ? false : MOCK_SERVICES;

export const notify = (error: unknown, name = "Error", context = "Context") => {
  if (error instanceof Error) {
    error.name = name;
    Bugsnag?.notify(error, e => {
      e.context = context;
    });
  }
  if (typeof error === "string") {
    const e = new Error(error);
    e.name = name;
    Bugsnag?.notify(e, e => {
      e.context = context;
    });
  }
};

export const detectWebView = () => {
  const isAndroidApp = !!(
    navigator.userAgent.indexOf("Android") > -1 &&
    navigator.userAgent.indexOf("wv") > -1
  );
  const isIOSApp = !!(
    navigator.userAgent.match(/iPhone|iPod|iPad/) &&
    navigator.userAgent.match(/AppleWebKit/) &&
    !navigator.userAgent.match(/Safari/)
  );
  return isAndroidApp || isIOSApp;
};
