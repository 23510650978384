/* istanbul ignore file */
import styled from "styled-components";

import { RadioButtonModifierStyledProps as Props } from "./RadioButtonModifier.types";

const RadioButtonModifierStyled = styled.div<Props>`
  display: flex;
  flex-direction: column;
  padding: 1.2rem 0;

  &.RadioButtonModifier .Button {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    cursor: ${props => (props.disabled ? "default" : "pointer")};

    :hover {
      box-shadow: none;
    }
  }

  .RadioButtonModifier {
    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__button {
      display: flex;
      border: none;
      align-items: flex-start;
      background-color: var(--palette-gray-s0-l95);
      flex-direction: column;

      .ModifierPrices {
        margin-left: 0;
      }
    }

    &__icon {
      width: 2.4rem;
      height: 2.4rem;
      border: 0.1rem solid var(--palette-gray-s0-l80);
      border-radius: 10rem;
      background-color: transparent;

      &:hover {
        cursor: pointer;
      }

      &--active {
        border: 0.7rem solid
          ${props =>
            props.disabled
              ? "var(--palette-primary-light)"
              : "var(--palette-primary)"};
      }
    }

    &__text {
      font-family: "Inter", sans-serif;
      font-size: 1.4rem;
      font-weight: 600;
      color: ${props =>
        props.disabled
          ? "var(--palette-gray-s0-l70-a48)"
          : "var(--palette-black-s0-l10)"};
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      &--active {
        font-weight: 600;
      }
    }
  }
`;

export default RadioButtonModifierStyled;
